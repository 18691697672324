var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-contain"},[_c('audio',{ref:"music",attrs:{"src":"https://music.163.com/song/media/outer/url?id=2014542879.mp3","loop":""}}),_c('div',{staticClass:"main-top"},[_c('span',[_vm._v("让科技创造学习新世界")]),(!_vm.music)?_c('div',{on:{"click":_vm.ListenMusic}},[_vm._v("播放主题曲")]):_c('div',{on:{"click":_vm.CloseMusic}},[_vm._v("暂停主题曲")])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"main-bottom-date"}),_vm._m(5),_c('div',{staticClass:"bottom"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-image"},[_c('div',{staticClass:"main-image-info"},[_c('img',{staticClass:"main-image-logo",attrs:{"src":require("./../assets/img/logo_black.jpg"),"alt":""}}),_c('div',{staticClass:"main-image-name"},[_vm._v("MSLM MATHS PIE")])]),_c('div',{staticClass:"image-menu"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title"},[_c('span',[_vm._v("边缘骇客编程实验室")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"latest-new"},[_c('div',[_vm._v("边缘骇客直播互动助手")]),_c('a',{attrs:{"href":"http://livehelper.willwaking.com/"}},[_vm._v("体验")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"latest-new"},[_c('div',[_vm._v("边缘骇客迪醒智能绘画")]),_c('a',{attrs:{"href":"http://livehelper.willwaking.com/"}},[_vm._v("体验")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title"},[_c('span',[_vm._v("秒数联盟数学派创新世界")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-bottom-content"},[_c('div',{staticClass:"main-app"},[_c('div',{staticClass:"app"},[_c('img',{attrs:{"src":require("./../assets/img/mini_app_minicat.jpg")}}),_c('div',[_vm._v("迷你单词英语猫")])]),_c('div',{staticClass:"app"},[_c('img',{attrs:{"src":require("./../assets/img/mini_app_answerstar.jpg")}}),_c('div',[_vm._v("数学派解题星球")])]),_c('div',{staticClass:"app"},[_c('img',{attrs:{"src":require("./../assets/img/mini_app_topproblem.jpg")}}),_c('div',[_vm._v("秒数联盟数学派")])]),_c('div',{staticClass:"app"},[_c('img',{attrs:{"src":require("./../assets/img/new_uri_millioncloud.jpg")}}),_c('div',[_vm._v("亿题云资料宝库")])]),_c('div',{staticClass:"app"},[_c('img',{attrs:{"src":require("./../assets/img/new_uri_solvinguniverse.jpg")}}),_c('div',[_vm._v("数学派刷题宇宙")])])])])
}]

export { render, staticRenderFns }
<template>
    <div class="mobile-contain">
        <audio src="https://music.163.com/song/media/outer/url?id=2014542879.mp3" ref="music" loop></audio>
        
        <div class="main-top">
            <span>让科技创造学习新世界</span>
            <div v-if="!music" @click="ListenMusic">播放主题曲</div>
            <div v-else @click="CloseMusic">暂停主题曲</div>
        </div>

        <div class="main-image">
            <div class="main-image-info">
                <img class="main-image-logo" src="./../assets/img/logo_black.jpg" alt="">
                <div class="main-image-name">MSLM MATHS PIE</div>
            </div>

            <div class="image-menu">
    
            </div>
        </div>

        <div class="main-title">
            <span>边缘骇客编程实验室</span>
        </div>

        <div class="latest-new">
            <div>边缘骇客直播互动助手</div>
            <a href="http://livehelper.willwaking.com/">体验</a>
        </div>

        <div class="latest-new">
            <div>边缘骇客迪醒智能绘画</div>
            <a href="http://livehelper.willwaking.com/">体验</a>
        </div>

        <div class="main-title">
            <span>秒数联盟数学派创新世界</span>
        </div>

        <div class="main-bottom-date"></div>
        <div class="main-bottom-content">
            <div class="main-app">
                <div class="app">
                    <img src="./../assets/img/mini_app_minicat.jpg">
                    <div>迷你单词英语猫</div>
                </div>
                <div class="app">
                    <img src="./../assets/img/mini_app_answerstar.jpg">
                    <div>数学派解题星球</div>
                </div>
                <div class="app">
                    <img src="./../assets/img/mini_app_topproblem.jpg">
                    <div>秒数联盟数学派</div>
                </div>
                <div class="app">
                    <img src="./../assets/img/new_uri_millioncloud.jpg">
                    <div>亿题云资料宝库</div>
                </div>
                <div class="app">
                    <img src="./../assets/img/new_uri_solvinguniverse.jpg">
                    <div>数学派刷题宇宙</div>
                </div>
            </div>
        </div>
        <div class="bottom"></div>
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            music: false
        }
    },
    methods: {
        ListenMusic() {
            this.$refs.music.play()
            this.music = true
            console.log("开始播放数学派主题曲《数学系和银河系邂逅》")
        },
        CloseMusic() {
            this.$refs.music.pause()
            this.music = false
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
.mobile-contain {
    font-family: "cloud";
    width: 100vw;
    height: 100vh;
    min-width: 360px;
    max-width: 768px;
    background-color: #24273B;
    overflow-y: scroll;
    font-size: 15px;
    margin: 0 auto;
}

.main-top {
    height: 60px;
    background-color: #151728;
    color: #4D516B;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 2;
    position: sticky;
    top: 0;
}

.main-top div {
    color: #6C84B8;
}

.main-image {
    margin: 20px;
    background-color: #151728;
    position: relative;
    z-index: 1;
    height: 180px;
    overflow: hidden;
    background-image: url(./../assets/img/cover.jpg);
    background-size: cover;
}

.main-image-info {
    margin-top: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
    color: #FFFFFF;
    font-size: 20px;
    text-shadow: 1px 1px 1px #000;
}

.main-image-logo {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: 60px;
}

.main-image:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-image: url("./../assets/img/cover.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(100px);
    opacity: 0.5;
}

.image-menu {
    display: flex;
    height: 40px;
    align-items: center;
    background-color: #22253F55;
}

.main-title {
    padding: 0 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4D516B;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.latest-new {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    margin-bottom: 0;
    z-index: 1;
    background-color: #151728;
    height: 65px;
    color: #9AB4EB;
    align-items: center;
    padding: 0 20px;
}

.latest-new a {
    background-color: #9AB4EB;
    border: none;
    line-height: 35px;
    height: 35px;
    color: white;
    padding: 0 20px;
    cursor: pointer;
}

.latest-new a:hover {
    background-color: #6C84B8;
}

.main-bottom-content {
    margin: 0 20px;
}

.main-app {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.app {
    width: calc(50% - 10px);
    height: 150px;
    background-color: #151728;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.app img {
    width: 60px;
    height: 60px;
    display: block;
    border: 1px solid #F5F5F5;
    box-sizing: border-box;
}

.app div {
    margin-top: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #D9D8E8;
    font-size: 13px;
}

.bottom {
    display: flex;
    align-items: center;
    background-color: #24273B;
    margin-top: 20px;
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import echarts from "echarts";
Vue.prototype.$echarts = echarts;

const axios = require('axios');
const instance = axios.create({
    baseURL: 'http://127.0.0.1:5000',
});

window.router=router

Vue.prototype.$instance = instance;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Index from "@/pages/Index"
import Mobile from "@/pages/Mobile"

const routes = [
    {
        path: "/",
        component: Index
    },
    {
        path: "/mobile",
        component: Mobile
    },
]
const router = new VueRouter({
    routes,
    mode: 'history',
})

router.beforeEach((to, from, next) => {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        if (to.path != '/mobile') {
            next({ path: '/mobile' })
        } else {
            next()
        }
    } else {
        if (to.path != '/') {
            next({ path: '/' })
        } else {
            next()
        }
    }
})

export default router